import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CommonAlert from '../../../commonComponents/CommonAlert'

class Accept extends Component {
    render() {
        return (
            <Modal isOpen={this.props.modal} className="modal-lg">
                <ModalHeader toggle={this.props.toggle}>Accept Request</ModalHeader>
                <ModalBody>
                    <CommonAlert color={this.props.alertType} message={this.props.alertMessage} show={this.props.alertMessage ? true : false} />
                    <div className="pt-3">
                        <div className="card p-3 bg-offwhite">
                            <h6 className="mb-1 text-muted text-uppercase fs-13">Location :</h6>
                            <p> {this.props && this.props.reqDetails && this.props.reqDetails.blood_request_data && this.props.reqDetails.blood_request_data.location} </p>
                            <h6 className="mb-1 text-muted text-uppercase fs-13">Requested by :</h6>
                            <p> {this.props && this.props.reqDetails && this.props.reqDetails.blood_request_data && this.props.reqDetails.blood_request_data.user_profile_data && this.props.reqDetails.blood_request_data.user_profile_data.full_name} </p>
                            <h6 className="mb-1 text-muted text-uppercase fs-13">Date & Time of blood required :</h6>
                            <p>
                                {this.props && this.props.reqDetails && this.props.reqDetails.blood_request_data && this.props.reqDetails.blood_request_data.date} {this.props && this.props.reqDetails && this.props.reqDetails.blood_request_data && this.props.reqDetails.blood_request_data.time}{" "}
                            </p>
                            <h6 className="mb-1 text-muted text-uppercase fs-13">Blood group :</h6>
                            <p> {this.props && this.props.reqDetails && this.props.reqDetails.blood_request_data && this.props.reqDetails.blood_request_data.blood_group} </p>
                            <h6 className="mb-1 text-muted text-uppercase fs-13">Blood Element :</h6>
                            <p className="m-0"> {this.props && this.props.reqDetails && this.props.reqDetails.blood_request_data && this.props.reqDetails.blood_request_data.blood_element} </p>
                        </div>
                        <h5 className="pt-3">
                            <b>Complete the following</b>
                        </h5>
                        <form>
                            <div className="pt-3">
                                <label htmlFor="lastdonated" className="form-label">
                                    Last blood donated
                                </label>
                                <input type="date" className="form-control height-45 border-8 text-lowercase" id="lastdonated" name="lastdonated" aria-describedby="nameHelp" value={this.props && this.props.lastDonatedDate} onChange={(e) => this.props.onInputChange("lastDonatedDate", e.target.value)} placeholder="DD/MM/YY" />
                            </div>
                            {
                                this.props && !this.props.show &&
                                <div className="pt-3">
                                    <label htmlFor="exampleInputName1" className="form-label">Could you please confirm the following? (Please pick the relevant ones)</label>
                                    <div className="row g-0">
                                        <div className="col-12">
                                            <div className="d-flex g-0 flex-wrap">
                                                {
                                                    this.props && this.props.userAnswers && this.props.userAnswers.length > 0 &&
                                                    this.props.userAnswers.map((quest, index) => {
                                                        return (
                                                            <div key={index} className="select-prechk mb-3">
                                                                <input type="checkbox" name="b-group"
                                                                    id={"quest" + index} onClick={() => this.props.markAnswer(quest)} />
                                                                <label htmlFor={"quest" + index} className={quest.answer ? "me-2 px-2 is-checked" : "me-2 px-2"}>{quest.question_text}</label>
                                                            </div>
                                                        )

                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="pt-3">
                                <label className="form-label">Note</label>
                                <input type="textarea" className="form-control height-45 border-8 text-lowercase" id="note" name="note" aria-describedby="nameHelp" onChange={(e) => this.props.onInputChange("note", e.target.value)} placeholder="Notes" />
                            </div>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-blue text-white  height-45 border-8 px-md-5 px-4 border-0 btn btn-secondary" onClick={this.props.submitAcceptReq}>
                        Confirm
                    </Button>
                    <Button className="btn btn-gray text-black  height-45 border-8 px-md-5 px-4 border-0 me-3 btn btn-secondary" onClick={this.props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default Accept;
