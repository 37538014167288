import React, { Component, Suspense } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Login from '../pages/Login'
import Register from '../pages/Register'
import Verification from '../pages/Verification'
import Dashboard from '../pages/Dashboard'
import DonorForm from '../pages/DonorForm'
import RequestList from '../pages/RequestList'
import BloodRequest from '../pages/BloodRequest'
import DonateList from '../pages/DonateList'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import { userDetails, getMasterData } from '../../api/user'
import BloodRequestList from '../pages/BloodRequestList'
import Header from '../commonComponents/Header'
import Footer from '../commonComponents/Footer'

class Routing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userDetails: {},
            bloodGroupList: []
        }
    }

    componentDidMount = () => {
        this.getUserDetails()
        this.loadData()
    }

    getUserDetails = () => {
        userDetails().then((data) => {
            this.setState({
                userDetails: data.response
            })
        })
    }

    loadData = () => {
        getMasterData().then((response) => {
            this.setState({
                bloodGroupList: response.response.blood_group
            })
        })
    }


    render() {

        const { userDetails, bloodGroupList } = this.state

        return (
            <div>
                <div className="wrapper d-flex align-items-center justify-content-center flex-column">
                    <div className="main-container container">
                        <div className="d-flex flex-column h-100 justify-content-between">
                            <Route path="/" render={(props) => <Header {...props} userDetails={userDetails} bloodGroupList={bloodGroupList} getUserDetails={this.getUserDetails} />} />
                            <Suspense fallback={<div>Loading...</div>}>
                                <Route path="/register" render={(props) => <Register {...props} bloodGroupList={bloodGroupList} getUserDetails={this.getUserDetails} />} />
                                <Route path="/dashboard" render={(props) => <Dashboard {...props} userDetails={userDetails} bloodGroupList={bloodGroupList} />} />
                                <Route path="/donor-form" render={(props) => <DonorForm {...props} getUserDetails={this.getUserDetails} />} />
                                <Route path="/new-blood-request" render={(props) => <BloodRequest {...props} bloodGroupList={bloodGroupList} userDetails={userDetails} />} />
                                <Route path="/my-requests" render={(props) => <RequestList {...props} userDetails={userDetails} bloodGroupList={bloodGroupList} />} />
                                <Route path="/donate-list" render={(props) => <DonateList {...props} userDetails={userDetails} bloodGroupList={bloodGroupList} />} />
                                <Route path="/blood-request-list" render={(props) => <BloodRequestList {...props} userDetails={userDetails} bloodGroupList={bloodGroupList} />} />
                            </Suspense>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Routing);