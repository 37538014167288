import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class CommonModal extends Component {

    copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.props.toggle()
    };


    render() {
        return (
            <Modal className="modal-dialog modal-sm modal-dialog-centered" isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>{this.props && this.props.title} </ModalHeader>
                <ModalBody className="contact">
                    <span>{this.props && this.props.content}</span><a href="#" onClick={(e) => e.preventDefault(), () => this.copyToClipboard(this.props && this.props.content)} className="contact-copy">Copy</a>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.props.toggle}>OK</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default CommonModal