import fetch from './handler'

export const getQuestionaire = () => {
    return fetch({
        url: '/user/get-questions/',
        method: 'GET'
    })
}

export const updateQuestionaire = (body) => {
    return fetch({
        url: '/user/update-questionnaire/',
        method: 'POST',
        body,
        jsonData: true
    })
}