import React, { Component } from 'react'
import { Alert } from 'reactstrap'
import Header from '../../commonComponents/Header'
import Footer from '../../commonComponents/Footer'
import { verifyOtp, resendOTP } from '../../../api/auth';
import { API_ROOT } from '../../../config';
import { Link } from 'react-router-dom';

class Verification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            otpValue: '',
            otpField1: '',
            otpField2: '',
            otpField3: '',
            otpField4: '',
            alertType: '',
            alertMessage: ''
        }
    }

    onInputChange = (key, e) => {

        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");

        if (value.length >= maxLength) {
            // Check if it's not the last input field
            if (parseInt(fieldIndex, 10) < 4) {
                // Get the next input field
                const nextSibling = document.querySelector(
                    `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
                );

                // If found, focus the next field
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }

        this.setState({
            [key]: value
        })
    }

    getOTPValue = () => {
        const { otpField1, otpField2, otpField3, otpField4 } = this.state

        return otpField1.concat(otpField2, otpField3, otpField4)
    }

    redirectToVerification = (response) => {

        if (response.is_profile_complete) {
            this.props.history.push({
                pathname: '/dashboard',
                state: {
                    userDetails: response
                }
            })
        } else {
            this.props.history.push({
                pathname: '/register',
                state: {
                    country_code: "91",
                    mobile_number: this.state.mobile_number
                }
            })
        }
    }

    disableAlert = () => {
        this.setState({
            alertType: '',
            alertMessage: ''
        })
    }

    resendOTPDetails = () => {
        let body = {
            country_code: this.props && this.props.location && this.props.location.state.country_code,
            mobile_number: this.props && this.props.location && this.props.location.state.mobile_number,
        }
        resendOTP(body).then((data) => {
            if (data.code == 1) {
                this.setState({
                    alertType: 'success',
                    alertMessage: data.msg
                }, () => {
                    setTimeout(this.disableAlert, 2000)
                })
            }
        })
    }

    verifyOtpDetail = () => {
        let body = {
            country_code: this.props && this.props.location && this.props.location.state.country_code,
            mobile_number: this.props && this.props.location && this.props.location.state.mobile_number,
            otp: this.getOTPValue(),
            // otp: "1234"
        }

        verifyOtp(body).then((response) => {
            if (response.code == 1) {
                this.setState({
                    alertType: 'success',
                    alertMessage: 'OTP verified successfully'
                }, () => {
                    window.localStorage.setItem('isLoggedIn', "true");
                    setTimeout(this.redirectToVerification(response.response), 2000)
                })
            }
        }).catch((response) => {
            this.setState({
                alertType: 'danger',
                alertMessage: response.msg
            })
        })
    }

    render() {

        const { otpField1, otpField2, otpField3, otpField4, alertMessage, alertType } = this.state

        return (
            <div className="wrapper d-flex align-items-center justify-content-center flex-column">
                <div className="main-container container">
                    <div className="d-flex flex-column h-100 justify-content-between">
                        <Header />

                        <section className="section-content w-100">
                            <div className="row h-100 d-md-flex d-block">
                                <div className="col-5 ps-0 d-none d-lg-block">
                                    <div className="section-lhs w-100 h-100 d-flex flex-column justify-content-end pb-5">
                                        {/* <h1 className="font-lato font-900">Join With Us And
                                            <span className="text-red d-block">Save Life</span>
                                            </h1>
                                            <p className="font-700">Save a life and be the reason for a smile!</p>  */}
                                    </div>
                                </div>
                                <div className="col-12 d-lg-none px-0 mt-4">
                                    <img src="/media/small-screen-banner.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-lg-7 mt-lg-0 mt-4">
                                    <div className="d-flex h-100 align-items-center">
                                        <div className="col-xxl-10 ps-lg-4">
                                            {
                                                alertMessage &&
                                                <Alert color={alertType}>
                                                    {alertMessage}
                                                </Alert>
                                            }
                                            <div className="section-rhs pe-md-5">
                                                <h2 className="m-0 font-lato font-500 fs-25">Enter OTP </h2>
                                                <p className="m-0 mt-2 sub-heading">Please enter the OTP sent to your mobile
                                                number <strong>{this.props && this.props.location && this.props.location.state.country_code} {this.props && this.props.location && this.props.location.state.mobile_number} </strong> | <br />
                                                    <Link to="/login" className="text-red font-600">Wrong
                                                    Number?</Link>
                                                </p>
                                                <form className="row g-3 mt-2">
                                                    <div className="col-sm-6">
                                                        <div className="d-flex align-items-center">
                                                            <input type="text" name="otp-1" maxLength={1} value={otpField1}
                                                                onChange={(e) => this.onInputChange('otpField1', e)}
                                                                className="form-control otp-field me-2 height-45 text-center font-700" />
                                                            <input type="text" name="otp-2" maxLength={1} value={otpField2}
                                                                onChange={(e) => this.onInputChange('otpField2', e)}
                                                                className="form-control otp-field me-2 height-45 text-center font-700" />
                                                            <input type="text" name="otp-3" maxLength={1} value={otpField3}
                                                                onChange={(e) => this.onInputChange('otpField3', e)}
                                                                className="form-control otp-field me-2 height-45 text-center font-700" />
                                                            <input type="text" name="otp-4" maxLength={1} value={otpField4}
                                                                onChange={(e) => this.onInputChange('otpField4', e)}
                                                                className="form-control otp-field height-45 text-center font-700" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <button type="button" onClick={this.verifyOtpDetail}
                                                            className="btn btn-blue text-white  height-45 border-8 px-5 w-100">Verify
                                                        & Proceed</button>
                                                    </div>
                                                </form>
                                                <p className="m-0 mt-3 sub-heading">Yet to receive the OTP? | <span onClick={this.resendOTPDetails}
                                                    className="text-red font-600 mouse">Resend OTP</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
                {/* <div className="md-footer d-lg-none my-4 fs-13">
                                                <p className="m-0">Copyright © 2021 All rights reserved.</p>
                                            </div>  */}
            </div >

        )
    }
}

export default Verification