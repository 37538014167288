import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import CommonPagination from '../../commonComponents/CommonPagination'
import { requestBloodHistory } from "../../../api/notification";
import "slick-carousel/slick/slick.css";

class RequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestList: [],
            full_name: "",
            country_code: "",
            mobile_number: "",
            blood_group: "",
            location: "",
            selectedPage: 1,
            pageCount: 0,
            perPage: 0
        };
    }

    componentDidMount = () => {
        if (this.props && this.props.userDetails) {
            this.setState({
                full_name: this.props.userDetails.full_name,
                country_code: this.props.userDetails.country_code,
                mobile_number: this.props.userDetails.mobile_number,
                blood_group: this.props.userDetails.blood_group,
                location: this.props.userDetails.location,
            });
        }
        this.getRequestList(1);
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.userDetails != prevProps.userDetails) {
            this.setState({
                full_name: this.props.userDetails.full_name,
                country_code: this.props.userDetails.country_code,
                mobile_number: this.props.userDetails.mobile_number,
                blood_group: this.props.userDetails.blood_group,
                location: this.props.userDetails.location,
            });
        }
    };

    getRequestList = (page) => {
        let params = {
            page
        }
        requestBloodHistory(params).then((data) => {
            // console.log("req blood history", data);
            this.setState({
                requestList: data.results,
                selectedPage: page,
                pageCount: data.total_pages,
                perPage: data.per_page
            });
        });
    };

    onPageChange = (page) => {
        let pageNumber = page.selected + 1
        this.setState({
            selectedPage: pageNumber
        }, () => {
            this.getRequestList(this.state.selectedPage)
        })
    }

    render() {
        const { requestList, full_name, country_code, mobile_number, blood_group, location, selectedPage, pageCount, perPage } = this.state;

        return (

            <section className="section-content w-100">
                <div className="d-xl-flex h-100 d-block px-xxl-4 px-1">
                    <div className="dashboard-lhs bg-offwhite border-10 me-xl-3 p-xl-4 p-2 d-flex flex-column justify-content-between mb-3 mb-xl-0">
                        <div className="topnav d-md-flex flex-xl-column justify-content-md-around justify-content-between position-relative" id="myTopnav">
                            <Link to="/dashboard">
                                <i className="flaticon-home"></i>Dashboard
                            </Link>
                            <Link to="/my-requests" className="active">
                                <i className="flaticon-blood-bag"></i>My Requests
                            </Link>
                            <Link to="/donate-list">
                                <i className="flaticon-blood-donation-4"></i>My Donations
                            </Link>
                            <Link to="/blood-request-list">
                                <i className="flaticon-blood-donation-4"></i>Blood donation Requests
                                        </Link>
                        </div>
                    </div>
                    <div className="dashboard-rhs bg-offwhite border-10 p-sm-3 p-2">
                        <div className="dashboard-rhs-header border-bottom pb-3">
                            <div className="d-flex justify-content-sm-between flex-sm-row flex-column align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="bg-box d-flex align-items-center justify-content-center border-10">{blood_group}</span>
                                    <div className="d-flex flex-column ms-3">
                                        <h2 className="m-0">{full_name}</h2>
                                        <p className="m-0 mt-1">
                                            {country_code} {mobile_number} | {location}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-md-end justify-content-center notify-request">
                                    <Link to="/new-blood-request" className="btn btn-blue text-white ms-3 border-8">
                                        <i className="flaticon-blood-donation-4"></i>
                                        <span className="">New Blood Request</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-rhs-body mt-3">
                            <div className="row h-100">
                                <div className="col-12 d-flex flex-column h-100 mt-md-0 mb-3">
                                    <div className="bg-white border-10 p-3 pb-0 h-100 mb-3">
                                        {/* <h6>10 Donors Accepted your blood request</h6> */}
                                        <div className="table-responsive">
                                            <table className="table fs-15 font-600 text-black m-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="5%" className="d-none d-md-table-cell">
                                                            Sl No
                                                        </th>
                                                        <th scope="col">Blood Group</th>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Time</th>
                                                        <th scope="col">Accepted By</th>
                                                        <th scope="col">Contact</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {requestList &&
                                                        requestList.length > 0 &&
                                                        requestList.map((req, index1) => {
                                                            return (
                                                                <tr key={index1}>
                                                                    <td className="d-none d-md-table-cell">{perPage * (selectedPage - 1) + (index1 + 1)}</td>
                                                                    <td>{req.blood_group}</td>
                                                                    <td>{req.location}</td>
                                                                    <td>{req.date}</td>
                                                                    <td>{req.time}</td>
                                                                    {req.notif_list &&
                                                                        req.notif_list.length > 0 &&
                                                                        req.notif_list.map((list, index2) => {
                                                                            return (
                                                                                <Fragment key={index2}>
                                                                                    <td>{list.donor.full_name}</td>
                                                                                    <td className="text-center">
                                                                                        {list.donor.country_code} {list.donor.mobile_number}
                                                                                    </td>
                                                                                </Fragment>
                                                                            );
                                                                        })}
                                                                    {req.notif_list && req.notif_list.length == 0 && (
                                                                        <Fragment>
                                                                            <td className="text-center">---</td>
                                                                            <td className="text-center">---</td>
                                                                        </Fragment>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })}
                                                    {requestList && requestList.length == 0 && (
                                                        <tr>
                                                            <td colSpan="7" className="text-center">
                                                                <p>You have not placed any blood requests yet.</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <CommonPagination selectedPage={selectedPage} pageCount={pageCount} onPageChange={this.onPageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default RequestList;
