import React, { Component } from 'react'
import { Alert } from 'reactstrap'
import Header from '../../commonComponents/Header'
import Footer from '../../commonComponents/Footer'
import { login } from '../../../api/auth';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            country_code: "",
            mobile_number: "",
            alertType: '',
            alertMessage: '',
            disableSubmit: false
        }
    }

    onInputChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    redirectToVerification = () => {
        this.props.history.push({
            pathname: '/verification',
            state: {
                country_code: "91",
                mobile_number: this.state.mobile_number
            }
        })
    }

    dismissAlert = () => {
        this.setState({
            alertType: '',
            alertMessage: '',
            disableSubmit: false
        })
    }

    submitLogin = () => {

        this.setState({
            disableSubmit: true
        })

        const { mobile_number } = this.state
        let valid = true

        if (mobile_number.length < 10) {
            valid = false
        }

        if (!valid) {
            this.setState({
                alertType: 'danger',
                alertMessage: 'Enter valid mobile number'
            }, () => {
                setTimeout(this.dismissAlert, 2000)
            })
            return
        }

        let body = {
            country_code: "91",
            mobile_number
        }

        login(body).then((response) => {
            if (response.code == 1) {
                this.setState({
                    alertType: 'success',
                    alertMessage: response.msg
                }, () => {
                    setTimeout(this.redirectToVerification, 2000)
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    alertMessage: error.msg,
                    alertType: 'danger',
                }, () => {
                    setTimeout(this.dismissAlert, 2000)
                })
            }
        })

    }

    render() {

        const { alertType, alertMessage, disableSubmit } = this.state

        return (
            <div className="wrapper d-flex align-items-center justify-content-center flex-column">
                <div className="main-container container">
                    <div className="d-flex flex-column h-100 justify-content-between">
                        <Header />

                        <section className="section-content w-100">
                            <div className="row h-100 d-md-flex d-block">
                                <div className="col-5 ps-0 d-none d-lg-block">
                                    <div className="section-lhs w-100 h-100 d-flex flex-column justify-content-end pb-5">
                                        {/* <h1 className="font-lato font-900">Join With Us And
                                            <span className="text-red d-block">Save Life</span>
                                        </h1>
                                        <p className="font-700">Save a life and be the reason for a smile!</p>  */}
                                    </div>
                                </div>
                                <div className="col-12 d-lg-none px-0 mt-4">
                                    <img src="/media/small-screen-banner.png" className="img-fluid" alt="" />
                                </div>
                                <div className="col-lg-7 mt-lg-0 mt-4">
                                    <div className="d-flex h-100 align-items-center form-area">
                                        <div className="col-xxl-10 ps-lg-4">
                                            <div className="section-rhs pe-md-5">
                                                {
                                                    alertMessage &&
                                                    <Alert color={alertType}>
                                                        {alertMessage}
                                                    </Alert>
                                                }
                                                <h2 className="m-0 font-lato font-500 fs-25">Login </h2>
                                                <p className="m-0 mt-2 sub-heading">OTP will be sent to your mobile number
                                                    for
                                                    verification
                                            </p>
                                                <form className="row g-sm-3 g-2 mt-0">
                                                    <div className="col-sm-6">
                                                        <div className="d-flex align-items-center">
                                                            <span className="country-code height-45">+91</span>
                                                            <input id="msg" type="text" onChange={(e) => this.onInputChange('mobile_number', e.target.value)} maxLength="10"
                                                                className="form-control number-pre-icon height-45" name="phone"
                                                                placeholder="Your phone number" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <button type="button" onClick={this.submitLogin} disabled={disableSubmit}
                                                            className="btn btn-blue text-white  height-45 border-8 px-5 w-100">Get
                                                        OTP</button>
                                                    </div>
                                                </form>
                                                <small className="text-muted fst-italic mt-3 d-block">"Blood donation is safe
                                                    for healthy adults. There’s no risk of contracting disease. New, sterile
                                                equipment is used for each donor."</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
                {/* <div className="md-footer d-lg-none my-4 fs-13">
                    <p className="m-0">Copyright © 2021 All rights reserved.</p>
                </div> */}
            </div>
        )
    }
}

export default Login
