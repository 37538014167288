import fetch from './handler'

export const bloodRequestList = (params) => {
    return fetch({
        url: '/user/request-notification-list/',
        method: 'GET',
        params
    })
}

export const requestBlood = (body) => {
    return fetch({
        url: '/user/request-blood/',
        method: 'POST',
        body,
        jsonData: true
    })
}

export const getMatchingDonorCount = (body) => {
    return fetch({
        url: '/user/matching-donor-count/',
        method: 'POST',
        body,
        jsonData: true
    })
}

export const acceptBloodRequest = (id, body) => {
    return fetch({
        url: `/user/blood-request-accept/${id}/`,
        method: 'POST',
        body,
        jsonData: true
    })
}

export const rejectBloodRequest = (id, body) => {
    return fetch({
        url: `/user/blood-request-reject/${id}/`,
        method: 'POST',
        body,
        jsonData: true
    })
}

export const donateHistory = (params) => {
    return fetch({
        url: '/user/blood-donation-history/',
        method: 'GET',
        params
    })
}

export const requestBloodHistory = (params) => {
    return fetch({
        url: '/user/request-blood-list/',
        method: 'GET',
        params
    })
}