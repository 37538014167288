import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

const isLoggedIn = (ComposedComponent, path) => {
    return class AuthCheck extends Component {
        constructor(props) {
            super(props);
            this.state = {
                keyPresent: false,
                checkComplete: false
            }
        }

        componentDidMount = () => {
            this.checkKey()
        }

        checkKey = () => {
            const loggedIn = localStorage.getItem("isLoggedIn");
            if (loggedIn == "true") {
                this.setState({
                    keyPresent: true,
                    checkComplete: true
                })
            } else {
                this.setState({
                    keyPresent: false,
                    checkComplete: true
                })
            }
        }

        render() {
            const { keyPresent, checkComplete } = this.state;
            if (checkComplete) {
                if (path == '/login') {
                    if (keyPresent) {
                        return <Redirect to="/dashboard" />
                    }
                    else {
                        return <ComposedComponent {...this.props} />
                    }
                }
                if (keyPresent) {
                    return <ComposedComponent {...this.props} />;
                }
                return <Redirect to="/login" />
            } else {
                return null;
            }
        }
    }
}

export default isLoggedIn;