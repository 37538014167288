import React, { Component } from 'react'
import { Alert } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getQuestionaire, updateQuestionaire } from '../../../api/questionaire';
import { markAsDonor } from '../../../api/user';

class DonorForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateOfBirth: '',
            lastDonatedDate: '',
            questionList: [],
            userAnswers: [],
            userAnsArray: [],
            alertType: '',
            alertMessage: ''
        }
    }

    componentDidMount = () => {
        this.getQuestionsList()
    }

    onInputChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    getQuestionsList = () => {
        getQuestionaire().then((response) => {
            // console.log("resp", response)
            if (response.code == 1) {
                this.setState({
                    questionList: response.response
                }, () => {
                    this.formatUserAnswers()
                })
            }
        })
    }

    formatUserAnswers = () => {
        let { userAnswers, questionList } = this.state

        questionList.forEach((question) => {
            userAnswers.push({
                question: question.id,
                question_text: question.question_text,
                answer: false,
            });
        })

        this.setState({
            userAnswers,
        })
    }

    markAnswer = (question) => {
        let { userAnswers } = this.state;

        userAnswers.map((quest) => {
            if (question.question == quest.question) {
                if (quest.answer == true) {
                    quest.answer = false;
                } else if (quest.answer == false) {
                    quest.answer = true;
                }
            }
        });

        this.setState({
            userAnswers,
        });
    };

    redirectToDashboard = () => {
        this.props.history.push('/dashboard')
    }

    dismissAlert = () => {
        this.setState({
            alertType: '',
            alertMessage: ''
        })
    }

    submitQuestionaire = () => {

        const { dateOfBirth, lastDonatedDate, userAnswers } = this.state
        let valid = true
        let fields = []

        if (!dateOfBirth) {
            valid = false
            fields.push('date of birth')
        }
        if (!lastDonatedDate) {
            valid = false
            fields.push('last donated date')
        }

        if (!valid) {
            this.setState({
                alertType: 'danger',
                alertMessage: 'Please enter valid ' + fields.join(',')
            }, () => {
                setTimeout(this.dismissAlert, 2000)
            })
            return
        }

        let body = {
            "dob": dateOfBirth,
            "last_donated_on": lastDonatedDate,
            "questionnaire": userAnswers
        }

        // console.log("body", body)

        if (this.props && this.props.location && this.props.location.state && this.props.location.state.fromPage == 'dashboard') {
            updateQuestionaire(body).then((response) => {
                // console.log("resp", response)
                if (response.code == 1) {
                    markAsDonor().then((data) => {
                        if (data) {
                            this.props.getUserDetails()
                            this.setState({
                                alertType: 'success',
                                alertMessage: "Form filled successfully"
                            }, () => {
                                setTimeout(this.redirectToDashboard, 2000)
                            })
                        }
                    })
                }
            }).catch((response) => {
                this.setState({
                    alertType: 'danger',
                    alertMessage: response.msg
                })
            })
        } else {
            updateQuestionaire(body).then((response) => {
                // console.log("resp", response)
                if (response.code == 1) {
                    this.props.getUserDetails()
                    this.setState({
                        alertType: 'success',
                        alertMessage: "Form filled successfully"
                    }, () => {
                        setTimeout(this.redirectToDashboard, 2000)
                    })
                }
            }).catch((response) => {
                this.setState({
                    alertType: 'danger',
                    alertMessage: response.msg
                })
            })
        }
    }



    render() {

        const { questionList, dateOfBirth, lastDonatedDate, alertType, alertMessage, userAnswers } = this.state
        const today = moment().format('YYYY-MM-DD')

        return (
            <section className="section-content w-100">
                <div className="row h-100 d-md-flex d-block">
                    <div className="col-5 ps-0 d-none d-lg-block">
                        <div className="section-lhs w-100 h-100 d-flex flex-column justify-content-end pb-5">
                            {/* <h1 className="font-lato font-900">Join With Us And
                                            <span className="text-red d-block">Save Life</span>
                                        </h1>
                                        <p className="font-700">Save a life and be the reason for a smile!</p>  */}
                        </div>
                    </div>
                    <div className="col-12 d-lg-none px-0 mt-4">
                        <img src="/media/small-screen-banner.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-7 mt-lg-0 mt-4">
                        <div className="d-flex h-100 align-items-center">
                            <div className="col-xxl-10 ps-lg-4">
                                {
                                    alertMessage &&
                                    <Alert color={alertType}>
                                        {alertMessage}
                                    </Alert>
                                }
                                <div className="section-rhs pe-md-5">
                                    <h2 className="m-0 font-lato font-500 fs-25">Fill up a little more about you
                                            </h2>
                                    <form>
                                        <div className="row mt-3">
                                            <div className="mb-3 col-sm-6">
                                                <label htmlFor="dob" className="form-label">Date of
                                                            birth</label>
                                                <input type="date" className="form-control height-45 border-8 text-lowercase" value={dateOfBirth} max={today}
                                                    id="dob" name="dob" aria-describedby="nameHelp" onChange={(e) => this.onInputChange('dateOfBirth', e.target.value)}
                                                    placeholder="DD/MM/YY" />
                                            </div>
                                            <div className="mb-3 col-sm-6">
                                                <label htmlFor="lastdonated" className="form-label">Last blood
                                                            donated</label>
                                                <input type="date" className="form-control height-45 border-8 text-lowercase" value={lastDonatedDate} max={today}
                                                    id="lastdonated" name="lastdonated" aria-describedby="nameHelp" onChange={(e) => this.onInputChange('lastDonatedDate', e.target.value)}
                                                    placeholder="DD/MM/YY" />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputName1" className="form-label">Could you please confirm the following? (Please pick the relevant ones)</label>
                                            <div className="row g-0">
                                                <div className="col-12">
                                                    <div className="d-flex g-0 flex-wrap">
                                                        {
                                                            userAnswers && userAnswers.length > 0 &&
                                                            userAnswers.map((quest, index) => {
                                                                return (
                                                                    <div key={index} className="select-prechk mb-3">
                                                                        <input type="checkbox" name="b-group"
                                                                            id={"quest" + index} onClick={() => this.markAnswer(quest)} />
                                                                        <label htmlFor={"quest" + index} className={quest.answer ? "me-2 px-2 is-checked" : "me-2 px-2"}>{quest.question_text}</label>
                                                                    </div>
                                                                )

                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <button type="button" onClick={this.submitQuestionaire}
                                                className="btn btn-blue text-white  height-45 border-8 px-5 w-50">Submit</button>
                                            {
                                                (this.props && this.props.location && this.props.location.state && this.props.location.state.fromPage != 'register') &&
                                                <Link to="/dashboard" className="btn btn-gray text-black  height-45 border-8 px-md-5 px-4 me-3">
                                                    Cancel
                                                                </Link>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default DonorForm