import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import MapComponent from './Map'
import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from "../../../../config";
import { updateProfile } from '../../../../api/user';

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage("en");


class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            blood_group: '',
            location: '',
            location_lat: '',
            location_lng: '',
            alertType: '',
            alertMessage: ''
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.modal != prevProps.modal) {
            this.setState({
                name: this.props && this.props.details && this.props.details.full_name,
                blood_group: this.props && this.props.details && this.props.details.blood_group_fk,
                location: this.props && this.props.details && this.props.details.location,
                alertType: '',
                alertMessage: ''
            }, () => {
                this.changeLocation(this.state.location)
            })
        }
    }

    changeLocation = (location) => {

        this.setState({
            location: location
        })

        Geocode.fromAddress(location).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({
                    location_lat: lat,
                    location_lng: lng,
                });
            },
            (error) => {
                console.error(error);
            }
        );
    };

    onInputChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeCoordinates = (latitude, longitude) => {
        this.setState({
            location_lat: latitude,
            location_lng: longitude,
        }, () => {
            Geocode.fromLatLng(latitude, longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    this.setState({
                        location: address
                    })
                },
                (error) => {
                    console.error(error);
                }
            );
        });
    };

    dismissAlert = () => {
        this.setState({
            alertType: '',
            alertMessage: ''
        })
    }

    submitEditProfile = () => {
        const { name, blood_group, location, location_lat, location_lng } = this.state
        let valid = true
        let fields = []

        if (!name) {
            valid = false
            fields.push('name')
        }
        if (!blood_group) {
            valid = false
            fields.push('blood group')
        }
        if (!location) {
            valid = false
            fields.push('location')
        }
        if (!location_lat || !location_lng) {
            valid = false
            fields.push('coordinates')
        }

        if (!valid) {
            this.setState({
                alertType: 'danger',
                alertMessage: 'Please enter valid ' + fields.join(',')
            }, () => {
                setTimeout(this.dismissAlert, 2000)
            })
            return
        }

        let body = {
            full_name: name,
            location_lat,
            location_lng,
            location,
            blood_group_fk: parseInt(blood_group, 10)

        }

        updateProfile(body).then((response) => {
            if (response) {
                this.props.toggle()
                this.props.getUserDetails()
                this.props.toggleDropdown()
            }
        })

    }

    render() {
        const { name, blood_group, location, location_lat, location_lng, alertType, alertMessage } = this.state

        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Edit profile</ModalHeader>
                <ModalBody>
                    {
                        alertMessage &&
                        <Alert isOpen={alertMessage ? true : false} color={alertType}>
                            {alertMessage}
                        </Alert>
                    }
                    <Form>
                        <FormGroup>
                            <Label for="name">Full name</Label>
                            <Input type="text" name="name" id="name" value={name} placeholder="Enter full name" className="mt-2" onChange={(e) => this.onInputChange('name', e.target.value)} />
                        </FormGroup>
                        <FormGroup className="pt-3">
                            <Label for="exampleSelect">Blood group</Label>
                            <Input type="select" name="select" id="exampleSelect" value={blood_group} onChange={(e) => this.onInputChange('blood_group', e.target.value)}>
                                <option value="">Select blood group</option>
                                {
                                    this.props && this.props.bloodGroupList && this.props.bloodGroupList.length > 0 &&
                                    this.props.bloodGroupList.map((group, index) => {
                                        return (
                                            <option key={index} value={group.id}>{group.blood_group}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup className="pt-3">
                            <Label for="location">Location</Label>
                            <Input type="text" name="location" id="location" value={location} placeholder="Enter full name" className="mt-2" onChange={(e) => this.changeLocation(e.target.value)} />
                        </FormGroup>
                        <FormGroup className="pt-3">
                            <label>Coordinates</label>
                            <MapComponent isMarkerShown onMarkerClick={(params) => this.changeCoordinates(params.latLng.lat(), params.latLng.lng())} latitude={location_lat} longitude={location_lng} />
                            {/* <small className="d-flex mt-3">Coordinates lat <span className="c-bold-blue px-2">{location_lat}</span> long <span className="c-bold-blue px-2">{location_lng}</span></small> */}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitEditProfile}>Submit</Button>
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default EditUser