import React, { Fragment } from 'react';
import ReactPaginate from 'react-paginate';

const CommonPagination = (props) => {
    return (
        <Fragment>
            {
                props.pageCount && props.pageCount > 1 &&
                <div >
                    <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={props.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={props.onPageChange}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        initialPage={props.selectedPage - 1}
                        forcePage={props.selectedPage - 1}
                    />
                </div>
            }
        </Fragment>
    )
}
export default CommonPagination

