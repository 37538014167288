import React, { Component, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class MatchingDonorCount extends Component {
    render() {
        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Matching donor count</ModalHeader>
                <ModalBody>
                    {
                        (this.props.count > 0) &&
                        <div>
                            {this.props.count} matching donors are found. <br />
                            Click Confirm to notify them
                        </div>
                    }
                    {
                        (this.props.count == 0) &&
                        <div>
                            No matching donors are found. <br />
                            We will update you as soon as donors are available for donation.
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.placeBloodRequest}>Confirm</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default MatchingDonorCount