import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import MapComponent from "./Map";
import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from "../../../config";

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage("en");

class Coordinates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location_lat: "",
            location_lng: "",
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.modal != prevProps.modal) {
            if (this.props && this.props.location != "") {
                this.changeLocation(this.props.location);
            }
        }
    };

    changeLocation = (location) => {
        Geocode.fromAddress(location).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({
                    location_lat: lat,
                    location_lng: lng,
                });
            },
            (error) => {
                console.error(error);
            }
        );
    };

    changeCoordinates = (latitude, longitude) => {
        this.setState({
            location_lat: latitude,
            location_lng: longitude,
        });
    };

    submitCoordinates = () => {
        this.props.changeCoordinates(this.state.location_lat, this.state.location_lng);
        this.props.toggle();
    };

    render() {
        const { location_lat, location_lng } = this.state;

        return (
            <Modal isOpen={this.props.modal} className="modal-lg">
                <ModalHeader toggle={this.props.toggle}>Select Coordinates</ModalHeader>
                <ModalBody>
                    <label className="form-label">Click on the map to get your exact Coordinates</label>
                    <MapComponent isMarkerShown onMarkerClick={(params) => this.changeCoordinates(params.latLng.lat(), params.latLng.lng())} latitude={location_lat} longitude={location_lng} />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-blue text-white  height-45 border-8 px-md-5 px-4 border-0" onClick={this.submitCoordinates}>
                        Submit
                    </Button>
                    {/* <Button className="btn btn-gray text-black  height-45 border-8 px-md-5 px-4 border-0 me-3" onClick={this.props.toggle}>
                        Cancel
                    </Button> */}
                </ModalFooter>
            </Modal>
        );
    }
}

export default Coordinates;
