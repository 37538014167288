import ReactDOM from 'react-dom'
import React from 'react'
import App from './components/App'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom'
import '../assets/stylesheets/styles.scss'

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
    , document.getElementById('app')
)

