import React, { Component } from 'react'

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="wrapper d-flex align-items-center justify-content-center flex-column vh-100">
                <div className="main-container container vh-100">
                    <div className="d-flex flex-column h-100">
                        <header className="py-3 w-100">
                            <div className="row g-0">
                                <div className="col ">
                                    <img src="/media/privacy-logo.png" className="img-fluid" alt="" />
                                </div>
                                {/* <div className="col col-sm-8 ">
                                    <div className="d-flex align-items-center justify-content-end h-100">
                                        <a href="#" className="btn btn-red text-white">Close</a>
                                    </div>
                                </div> */}
                            </div>
                        </header>
                        <section className="section-content w-100 mb-3 p-4 h-100">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="mb-4">Disclaimer & Privacy Policy </h1>
                                    <p>This application functions with the sole aim to promote and spread the awareness among the people to donate blood.</p>
                                    <h2>Contacting Blood Donors</h2>
                                    <p>The users need to utilize the information provided in the application and approach the list of donors.The details of the donors are displayed only to facilitate the users for contacting them when in need of blood in case of any emergency. We are not responsible for the willingness/unwillingness to donate blood expressed by the donor listed on the site.</p>
                                    <h2>Content</h2>
                                    <ul>
                                        <li>Is provided for information purposes only.</li>
                                        <li>Is not shared with third parties.</li>
                                        <li>The donor information available on this website is made available to us by the donors and it's purely the donor's right to continue or to withdraw any information available at any point of time.</li>
                                        <li>We are not responsible for any inaccuracy in the information available on the application.</li>
                                        <li>We are not responsible for any misuse of contact information displayed in the website. If you feel that your contact details are being misused, then contact our help desk number immediately.</li>
                                    </ul>
                                    <h2>External Links</h2>
                                    <p>This application may contain links to other websites or resources only for the convenience of the users. We are not responsible for the content of these external sites, nor does endorse, warrant, or guarantee the products, services, or information described or offered on those sites. It is the responsibility of the user to examine the copyright and licensing restrictions of linked pages and to secure all necessary permission.</p>
                                    <h2>Rights</h2>
                                    <p>We reserve the right to change, modify or discontinue any aspect of the application at any time, including any information or its contents or features.</p>
                                    <h2>Copyrights & Trademarks</h2>
                                    <p>No information or content on the application may be copied, modified, reproduced, republished, uploaded, transmitted, posted, or distributed in any form without the prior written consent of our team. Unauthorized use of any information or content appearing on the application shall violate copyright, trademark and other applicable laws and could result in criminal or civil penalties.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicy