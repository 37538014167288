import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { bloodRequestList, rejectBloodRequest, acceptBloodRequest } from "../../../api/notification";
import CommonAlert from "../../commonComponents/CommonAlert";
import CommonPagination from '../../commonComponents/CommonPagination'
import { getQuestionaire } from "../../../api/questionaire";
import Accept from "./Accept";
import Reject from "./Reject";

class BloodRequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bloodRequestList: [],
            full_name: "",
            country_code: "",
            mobile_number: "",
            blood_group: "",
            location: "",
            alertShow: false,
            alertMessage: "",
            alertColour: "",
            acceptRequestModal: false,
            rejectRequestModal: false,
            acceptRequestDetails: {},
            rejectRequestId: -1,
            questionnaire: [],
            userAnswers: [],
            lastDonatedDate: "",
            note: "",
            rejectReason: "",
            acceptModalAlertMesasge: "",
            questionaireShow: false,
            selectedPage: 1,
            pageCount: 0,
            perPage: 0
        };
    }

    componentDidMount = () => {
        if (this.props && this.props.userDetails) {
            this.setState({
                full_name: this.props.userDetails.full_name,
                country_code: this.props.userDetails.country_code,
                mobile_number: this.props.userDetails.mobile_number,
                blood_group: this.props.userDetails.blood_group,
                location: this.props.userDetails.location,
                lastDonatedDate: this.props.userDetails.last_donated_on,
                questionaireShow: this.props.userDetails.additional_info_flag,
            });
        }
        this.getBloodRequestList(1);
        this.getUserQuestions();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.userDetails != prevProps.userDetails) {
            this.setState({
                full_name: this.props.userDetails.full_name,
                country_code: this.props.userDetails.country_code,
                mobile_number: this.props.userDetails.mobile_number,
                blood_group: this.props.userDetails.blood_group,
                location: this.props.userDetails.location,
                lastDonatedDate: this.props.userDetails.last_donated_on,
            });
        }
    };

    getBloodRequestList = (page) => {
        let params = {
            page
        }
        bloodRequestList(params).then((data) => {
            // console.log("blood req list total", data);
            this.setState({
                bloodRequestList: data.results,
                selectedPage: page,
                pageCount: data.total_pages,
                perPage: data.per_page
            });
        });
    };

    getUserQuestions = () => {
        getQuestionaire().then((data) => {
            if (data) {
                this.setState(
                    {
                        questionnaire: data.response,
                    },
                    () => {
                        let { questionnaire } = this.state;
                        let { userAnswers } = this.state;

                        questionnaire.forEach((question, index) => {
                            let answer = {
                                question: question.id,
                                question_text: question.question_text,
                                answer: question.user_answer,
                            };

                            userAnswers.push(answer);
                        });

                        this.setState({
                            userAnswers: userAnswers,
                        });
                    }
                );
            }
        });
    };

    dismissAlert = () => {
        this.setState({
            alertShow: false,
            alertMessage: "",
            alertColour: "",
        });
    };

    toggleAcceptRequestModal = () => {
        this.setState((prevState) => ({
            acceptRequestModal: !prevState.acceptRequestModal,
        }));
    };

    toggleRejectRequestModal = () => {
        this.setState((prevState) => ({
            rejectRequestModal: !prevState.rejectRequestModal,
        }));
    };

    onInputChange = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    acceptRequest = (request) => {
        // console.log("req", request)
        this.setState(
            {
                acceptRequestDetails: request,
            },
            () => {
                this.toggleAcceptRequestModal();
            }
        );
    };

    dismissAlert = () => {
        this.setState({
            alertShow: false,
            alertMessage: "",
            alertColour: "",
        });
    };

    dismissModalAlert = () => {
        this.setState({
            acceptModalAlertMesasge: "",
        });
    };

    markAnswer = (question) => {
        let { userAnswers } = this.state;

        userAnswers.map((quest) => {
            if (question.question == quest.question) {
                if (quest.answer == true) {
                    quest.answer = false;
                } else if (quest.answer == false) {
                    quest.answer = true;
                }
            }
        });

        this.setState({
            userAnswers,
        });
    };

    submitAcceptReq = () => {
        const { lastDonatedDate } = this.state;
        let valid = true;
        let message = "";

        if (!lastDonatedDate) {
            valid = false;
            message = "Please select last donated date.";
        }

        if (!valid) {
            this.setState(
                {
                    acceptModalAlertMesasge: message,
                },
                () => {
                    setTimeout(this.dismissModalAlert, 2000);
                }
            );
            return;
        }

        let body = {
            note: this.state.note,
            last_donated_on: lastDonatedDate,
            location_lat: this.props && this.props.userDetails && this.props.userDetails.location_lat,
            location_lng: this.props && this.props.userDetails && this.props.userDetails.location_lng,
            questionaire: this.state.userAnswers,
        };

        // console.log("bdy", body)

        acceptBloodRequest(this.state.acceptRequestDetails.id, body)
            .then((response) => {
                if (response) {
                    this.toggleAcceptRequestModal();
                    this.getBloodRequestList(this.state.selectedPage);
                }
            })
            .catch((response) => {
                // console.log("response", response)
                if (response) {
                    this.toggleAcceptRequestModal();
                    this.setState(
                        {
                            alertShow: true,
                            alertMessage: response.msg,
                            alertColour: "danger",
                        },
                        () => {
                            setTimeout(this.dismissAlert, 2000);
                        }
                    );
                }
            });
    };

    rejectRequest = (id) => {
        this.setState(
            {
                rejectRequestId: id,
            },
            () => {
                this.toggleRejectRequestModal();
            }
        );
    };

    submitRejectReq = (note) => {
        let body = {
            note: note,
        };
        // console.log("id,body", this.state.rejectRequestId, body)

        rejectBloodRequest(this.state.rejectRequestId, body)
            .then((data) => {
                if (data) {
                    this.toggleRejectRequestModal();
                    this.getBloodRequestList(this.state.selectedPage);
                }
            })
            .catch((response) => {
                this.toggleRejectRequestModal();
                this.setState(
                    {
                        alertShow: true,
                        alertMessage: response.msg,
                        alertColour: "danger",
                    },
                    () => {
                        setTimeout(this.dismissAlert, 2000);
                    }
                );
            });
    };

    onPageChange = (page) => {
        let pageNumber = page.selected + 1
        this.setState({
            selectedPage: pageNumber
        }, () => {
            this.getBloodRequestList(this.state.selectedPage)
        })
    }

    render() {
        const { full_name, country_code, mobile_number, blood_group, location, bloodRequestList, alertColour, alertMessage, alertShow, acceptRequestModal, acceptRequestDetails, rejectRequestModal, lastDonatedDate, userAnswers, questionaireShow, selectedPage, pageCount, perPage } = this.state;

        return (
            <section className="section-content w-100">
                <div className="d-xl-flex h-100 d-block px-xxl-4 px-1">
                    <div className="dashboard-lhs bg-offwhite border-10 me-xl-3 p-xl-4 p-2 d-flex flex-column justify-content-between mb-3 mb-xl-0">
                        <div className="topnav d-md-flex flex-xl-column justify-content-md-around justify-content-between position-relative" id="myTopnav">
                            <Link to="/dashboard">
                                <i className="flaticon-home"></i>Dashboard
                            </Link>
                            <Link to="/my-requests">
                                <i className="flaticon-blood-bag"></i>My Requests
                            </Link>
                            <Link to="/donate-list">
                                <i className="flaticon-blood-donation-4"></i>My Donations
                            </Link>
                            <Link to="/blood-request-list" className="active">
                                <i className="flaticon-blood-donation-4"></i>Blood donation Requests
                            </Link>
                        </div>
                    </div>
                    <div className="dashboard-rhs bg-offwhite border-10 p-sm-3 p-2">
                        <div className="dashboard-rhs-header border-bottom pb-3">
                            <div className="d-flex justify-content-sm-between flex-sm-row flex-column align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="bg-box d-flex align-items-center justify-content-center border-10">{blood_group}</span>
                                    <div className="d-flex flex-column ms-3">
                                        <h2 className="m-0">{full_name}</h2>
                                        <p className="m-0 mt-1">
                                            {country_code} {mobile_number} | {location}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-md-end justify-content-center notify-request">
                                    <Link to="/new-blood-request" className="btn btn-blue text-white ms-3 border-8">
                                        <i className="flaticon-blood-donation-4"></i>
                                        <span className="">New Blood Request</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-rhs-body mt-3">
                            <CommonAlert show={alertShow} color={alertColour} message={alertMessage} />
                            <div className="row h-100">
                                <div className="col-12 d-flex flex-column h-100 mt-md-0 mb-3">
                                    <div className="bg-white border-10 p-3 pb-0 h-100 mb-3">
                                        {/* <h6>10 Donors Accepted your blood request</h6> */}
                                        <div className="table-responsive">
                                            <table className="table fs-15 font-600 text-black m-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="5%" className="d-none d-md-table-cell">
                                                            Sl No
                                                        </th>
                                                        <th>Blood group</th>
                                                        <th scope="col" width="45%">
                                                            Location
                                                        </th>
                                                        <th scope="col">Date and Time</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" width="20%">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bloodRequestList &&
                                                        bloodRequestList.length > 0 &&
                                                        bloodRequestList.map((list, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                                    <td>{list.blood_request_data.blood_group}</td>
                                                                    <td>{list.blood_request_data.location}</td>
                                                                    <td>
                                                                        {list.blood_request_data.date} {list.blood_request_data.time}
                                                                    </td>
                                                                    <td>{list.is_accepted ? "Accepted" : list.is_declined ? "Declined" : "---"}</td>

                                                                    {!list.is_accepted && !list.is_declined && (
                                                                        <Fragment>
                                                                            <td>
                                                                                <button onClick={() => this.acceptRequest(list)} className="btn btn-red text-white border-10 py-1 fs-13">
                                                                                    Accept
                                                                                </button>
                                                                                <button onClick={() => this.rejectRequest(list.id)} className="btn btn-link ms-2 py-1">
                                                                                    Cancel
                                                                                </button>
                                                                            </td>
                                                                        </Fragment>
                                                                    )}
                                                                    {(list.is_accepted || list.is_declined) && (
                                                                        <Fragment>
                                                                            <td className="text-center">---</td>
                                                                        </Fragment>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })}
                                                    {bloodRequestList && bloodRequestList.length == 0 && (
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                <p>There are no upcoming blood donation requests.</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                            <CommonPagination selectedPage={selectedPage} pageCount={pageCount} onPageChange={this.onPageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Accept modal={acceptRequestModal} toggle={this.toggleAcceptRequestModal} reqDetails={acceptRequestDetails} onInputChange={this.onInputChange} submitAcceptReq={this.submitAcceptReq} alertType="danger" alertMessage={this.state.acceptModalAlertMesasge} lastDonatedDate={lastDonatedDate} userAnswers={userAnswers} markAnswer={this.markAnswer} show={questionaireShow} />
                <Reject modal={rejectRequestModal} toggle={this.toggleRejectRequestModal} submitRejectReq={this.submitRejectReq} />
            </section>
        );
    }
}

export default BloodRequestList;
