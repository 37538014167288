import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Routing from '../Routing'
import Login from '../pages/Login'
import isLoggedIn from '../HOC/isLoggedIn'
import Verification from '../pages/Verification'
import PrivacyPolicy from '../pages/PrivacyPolicy'

class App extends Component {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route path="/login" component={isLoggedIn(Login, "/login")} />
                    <Route path="/verification" render={(props) => <Verification {...props} />} />
                    <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
                    <Route path="*" component={isLoggedIn(Routing)} />
                </Switch>
            </div>
        )
    }
}

export default App