import fetch from './handler'

export const login = (body) => {
    return fetch({
        url: '/user/login/',
        method: 'POST',
        body,
        jsonData: true
    })
}

export const resendOtp = (body) => {
    return fetch({
        url: '/user/resend-otp/',
        method: 'POST',
        body,
        jsonData: true
    })
}

export const verifyOtp = (body) => {
    return fetch({
        // url: '/user/verify-otp/',
        url: '/user/session-verify-otp/',
        method: 'POST',
        body,
    })
}

export const refreshToken = (body) => {
    return fetch({
        url: '/user/get-refresh-token/',
        method: 'POST',
        body,
    })
}

export const resendOTP = (body) => {
    return fetch({
        url: '/user/resend-otp/',
        method: 'POST',
        body,
        jsonData: true
    })
}