import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return (
            <footer className="w-100 footer px-xxl-4 d-flex align-center pb-sm-0 pb-4">
                <div className="row w-100 g-0">
                    <div className="col-lg-4 col-6 d-lg-flex align-items-center d-none">
                        <p className="m-0">Copyright © 2021 All rights reserved.</p>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div
                            className="d-flex align-items-sm-center justify-content-lg-center h-100 flex-sm-row flex-column  mt-sm-0 mt-3">
                            <p className="m-0 me-sm-2">Download App</p>
                            <div className="d-flex align-items-center  mt-sm-0 mt-2">
                                <a href="#"
                                    className="btn app-icon me-2 d-flex align-items-center justify-content-center"><i
                                        className="flaticon-apple"></i></a>
                                <a href="#"
                                    className="btn app-icon d-flex align-items-center justify-content-center"><i
                                        className="flaticon-playstore"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-6 mt-sm-0 mt-3">
                        <div
                            className="d-flex align-items-sm-center justify-content-end h-100 flex-sm-row flex-column">
                            <p className="m-0">Powered By</p>
                            <a href="#" className="ms-sm-3"><img src="./media/caritas-logo.png" alt="" /></a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer