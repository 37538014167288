import React from 'react';
import { Alert } from 'reactstrap';

const CommonAlert = (props) => {
    return (
        props.show &&
        <Alert color={props.color} isOpen={props.show} className="m-0">
            {props.message}
        </Alert>
    )
}
export default CommonAlert
