import fetch from './handler'

export const getMasterData = () => {
    return fetch({
        url: '/user/master-api/',
        method: 'GET'
    })
}

export const updateProfile = (body) => {
    return fetch({
        url: '/user/update-profile/',
        method: 'PUT',
        body,
        jsonData: true
    })
}

export const userDetails = () => {
    return fetch({
        url: '/user/profile',
        method: 'GET'
    })
}

export const userLogout = () => {
    return fetch({
        url: '/user/logout/',
        method: 'POST'
    })
}

export const markAsDonor = () => {
    return fetch({
        url: '/user/mark-as-donor/',
        method: 'GET'
    })
}