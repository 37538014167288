let {
    apiRoot,
    googleApiKey
} = {};

if (process.env.NODE_ENV === "production") {
    apiRoot = "**APIROOT**";
    googleApiKey = "**GOOGLE_API_KEY**"
} else if (process.env.NODE_ENV === "development") {
    apiRoot = "https://stag-api.healthyworld.app";
    googleApiKey = "AIzaSyDMrDbL9bBgkoaMli9pbXaWHer982hmXbI";
}

export const API_ROOT = apiRoot;
export const GOOGLE_API_KEY = googleApiKey