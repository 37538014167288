import React, { Component, Fragment } from "react";
import "./index.css";
import Header from "../../commonComponents/Header";
import Footer from "../../commonComponents/Footer";
import { Link } from "react-router-dom";
import { dashboardDetails } from "../../../api/dashboard";
import CommonModal from "../../commonComponents/CommonModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            full_name: "",
            country_code: "",
            mobile_number: "",
            blood_group: "",
            location: "",
            myReqCount: 0,
            myReqList: [],
            receivedReqCount: 0,
            receivedReqList: [],
            donatedCount: 0,
            totalRequestCount: 0,
            donor: false,
            showNotification: false,
            userDetails: {},
            modalContent: "",
            notificationOpen: false,
            latestReq: []
        };
    }

    componentDidMount = () => {
        this.setState({
            full_name: this.props.userDetails.full_name,
            country_code: this.props.userDetails.country_code,
            mobile_number: this.props.userDetails.mobile_number,
            blood_group: this.props.userDetails.blood_group,
            location: this.props.userDetails.location,
            lastDonatedDate: this.props.userDetails.last_donated_on,
        });
        this.getDashboardDetails();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.userDetails != prevProps.userDetails) {
            this.setState({
                full_name: this.props.userDetails.full_name,
                country_code: this.props.userDetails.country_code,
                mobile_number: this.props.userDetails.mobile_number,
                blood_group: this.props.userDetails.blood_group,
                location: this.props.userDetails.location,
                lastDonatedDate: this.props.userDetails.last_donated_on,
            });
        }
    };

    getDashboardDetails = () => {
        dashboardDetails().then((response) => {
            // console.log("dataaaaaaaa", response);
            if (response.code == 1) {
                this.setState(
                    {
                        myReqCount: response.response.my_requests_count,
                        myReqList: response.response.my_requests,
                        receivedReqCount: response.response.recieved_requests_count,
                        receivedReqList: response.response.recieved_requests_list,
                        donatedCount: response.response.requests_accepted_count,
                        totalRequestCount: response.response.total_requests_count,
                        latestReq: response.response.latest_requests
                    },
                    () => {
                        if (this.state.receivedReqCount > 0) {
                            const notificationOpen = localStorage.getItem('notificationOpen');
                            if (!(notificationOpen && notificationOpen == "true")) {
                                this.showNotifications();
                            }
                        }
                    }
                );
            }
        });
    };

    viewAllNotifications = () => {
        this.setState({
            notificationOpen: true
        }, () => {
            window.localStorage.setItem('notificationOpen', "true");
            this.props.history.push('/blood-request-list')
        })
    }

    showNotifications = () => {
        this.setState((prevState) => ({
            showNotification: !prevState.showNotification,
        }));
    };

    showUserDetails = (details) => {
        // console.log("details", details);
        this.setState(
            {
                userDetails: details,
                modalContent: details.donor.country_code + " " + details.donor.mobile_number,
            },
            () => {
                this.toggleModal();
            }
        );
    };

    toggleModal = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    render() {
        const { full_name, country_code, mobile_number, blood_group, location, myReqCount, myReqList, donatedCount, totalRequestCount, showNotification, receivedReqCount, modal, modalContent, notificationOpen, latestReq } = this.state;

        return (
            <section className="section-content w-100">
                <div className="d-xl-flex h-100 d-block px-xxl-4 px-1">
                    <div className="dashboard-lhs bg-offwhite border-10 me-xl-3 p-xl-4 p-2 d-flex flex-column justify-content-between mb-3 mb-xl-0">
                        <div className="topnav d-md-flex flex-xl-column justify-content-md-around justify-content-between position-relative" id="myTopnav">
                            <Link to="/dashboard" className="active">
                                <i className="flaticon-home"></i>Dashboard
                            </Link>
                            <Link to="/my-requests">
                                <i className="flaticon-blood-bag"></i>My Requests
                            </Link>
                            <Link to="/donate-list">
                                <i className="flaticon-blood-donation-4"></i>My Donations
                            </Link>
                            <Link to="/blood-request-list">
                                <i className="flaticon-blood-donation-4"></i>Blood donation Requests
                            </Link>
                        </div>
                    </div>

                    <div className="dashboard-rhs bg-offwhite border-10 p-sm-3 p-2">
                        <div className="dashboard-rhs-header border-bottom pb-3">
                            <div className="d-flex justify-content-sm-between flex-sm-row flex-column align-items-center">
                                <div className="d-flex align-items-center">
                                    <span className="bg-box d-flex align-items-center justify-content-center border-10">{this.props && this.props.userDetails && this.props.userDetails.blood_group}</span>
                                    <div className="d-flex flex-column ms-3">
                                        <h2 className="m-0">{full_name}</h2>
                                        <p className="m-0 mt-1">
                                            {country_code} {mobile_number} | {location}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-md-end justify-content-center notify-request">
                                    <span className="position-relative notify">
                                        <i onClick={this.showNotifications} className="flaticon-bell"></i>
                                        <span className={receivedReqCount ? "notification-active position-absolute" : "position-absolute"}></span>
                                        <div className={(showNotification && !notificationOpen) ? "notification-area text-center show" : "notification-area text-center"}>
                                            {receivedReqCount > 0 && (
                                                <Fragment>
                                                    You have received {receivedReqCount} new request for <strong className="text-red">{blood_group}</strong> group.
                                                    <button onClick={this.viewAllNotifications} className="btn btn-red text-white border-10 w-50 mx-auto text-center p-1 d-block mt-2" to="/blood-request-list">
                                                        View All
                                                    </button>
                                                    {/* <a href="#" className="btn btn-blue text-white ms-3 border-8 fs-14">
                                                                            Donate
                                                                        </a>
                                                                        <a href="#" className="btn btn-gray text-black ms-3 border-8 fs-14">
                                                                            Not Available
                                                                        </a> */}
                                                </Fragment>
                                            )}
                                            {receivedReqCount == 0 && <Fragment>No requests for the time being.</Fragment>}
                                        </div>
                                    </span>
                                    <Link to="/new-blood-request" className="btn btn-blue text-white ms-3 border-8">
                                        <i className="flaticon-blood-donation-4"></i>
                                        <span className="">New Blood Request</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-rhs-body mt-3">
                            <div className="row h-100">
                                <div className="col-md-4 d-flex flex-column h-100">
                                    <div className="bg-white border-10 d-flex flex-column h-100 justify-content-between">
                                        <div>
                                            <div className="db-header p-3">
                                                <h5 className="mb-0">Request Notification</h5>
                                                {myReqCount > 0 && <p> {myReqCount} Donor(s) accepted your blood request</p>}
                                            </div>
                                            <div className="db-body px-3 h-scroll">
                                                {myReqList && myReqList.length > 0 && (
                                                    <Fragment>
                                                        <h6>My requests</h6>
                                                        {myReqList.map((notification, index1) => {
                                                            return (
                                                                <Fragment key={index1}>
                                                                    {notification.notif_list &&
                                                                        notification.notif_list.length > 0 &&
                                                                        notification.notif_list.map((notif, index2) => {
                                                                            return (
                                                                                <div key={index2} className="d-flex align-items-center justify-content-between mb-2 border-bottom pb-2">
                                                                                    <div>
                                                                                        <h6 className="mb-0">{notif.donor.full_name}</h6>
                                                                                        <p>{notif.donor.location}</p>
                                                                                    </div>
                                                                                    <div onClick={() => this.showUserDetails(notif)}>
                                                                                        <i className="flaticon-phone-call"></i>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </Fragment>
                                                )}
                                                {myReqCount == 0 && (
                                                    <Fragment>
                                                        <div className="text-center pt-5">
                                                            <img src="/media/no-requests.png" alt="" />
                                                            {
                                                                latestReq && latestReq.length == 0 &&
                                                                <p>No notification for the time being.</p>
                                                            }
                                                            {
                                                                latestReq && latestReq.length > 0 &&
                                                                <p>Waiting for donors to accept the request.</p>
                                                            }
                                                        </div>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>
                                        <div className="db-footer d-flex align-items-center justify-content-center pb-2">
                                            <Link to="/my-requests">View All</Link>
                                        </div>
                                    </div>
                                    <div className="bg-white border-10 h-50 my-3 d-flex flex-column ">
                                        <div className="db-header p-3">
                                            <h5 className="mb-0">Donate / Request History</h5>
                                            <p>Total Transactions with blood bank (in last 7 days)</p>
                                        </div>
                                        <div className="db-body px-3 d-flex justify-content-between py-2">
                                            <div className="border-end col">
                                                <h6>{donatedCount}</h6>
                                                <p>Donate Blood</p>
                                            </div>
                                            <div className="col ps-5">
                                                <h6>{totalRequestCount}</h6>
                                                <p>Request Blood</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 d-flex flex-column h-100 mt-3 mt-md-0">
                                    <div className="bg-white border-10 h-100 p-3 pb-0 cbtd-table mb-3">
                                        <h6>Compatible Blood Type Donors</h6>
                                        <table className="table fs-15 font-600 text-black m-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Blood Type</th>
                                                    <th scope="col">Donate Blood To</th>
                                                    <th scope="col">Receive Blood From</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>A+</td>
                                                    <td>A+ AB+</td>
                                                    <td className="text-center">A+ A- O+ O-</td>
                                                </tr>
                                                <tr>
                                                    <td>O+</td>
                                                    <td>O+ A+ B+ AB+</td>
                                                    <td className="text-center">O+ O-</td>
                                                </tr>
                                                <tr>
                                                    <td>B+</td>
                                                    <td>B+ AB+</td>
                                                    <td className="text-center">B+ B- O+ O-</td>
                                                </tr>
                                                <tr>
                                                    <td>AB+</td>
                                                    <td>AB+</td>
                                                    <td className="text-center">Everyone</td>
                                                </tr>
                                                <tr>
                                                    <td>A-</td>
                                                    <td>A+ A- AB+ AB-</td>
                                                    <td className="text-center">A- O-</td>
                                                </tr>
                                                <tr>
                                                    <td>O-</td>
                                                    <td>Everyone</td>
                                                    <td className="text-center">O-</td>
                                                </tr>
                                                <tr>
                                                    <td>B-</td>
                                                    <td>B+ B- AB+ AB-</td>
                                                    <td className="text-center">B- O-</td>
                                                </tr>
                                                <tr>
                                                    <td>AB-</td>
                                                    <td>AB+ AB-</td>
                                                    <td className="text-center">AB- A- B- O-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {!(this.props && this.props.userDetails && this.props.userDetails.donor) && (
                                        <div className="bg-white border-10 h-25 mb-3 d-flex align-items-md-center align-items-start justify-content-between px-3 py-md-2 py-3 flex-md-row flex-column">
                                            <div className="d-flex align-items-center">
                                                <i className="flaticon-blood-donation me-2"></i>
                                                <div>
                                                    <h4 className="font-700 text-red fs-16 text-uppercase m-0">BE A Real Hero</h4>
                                                    <p className="fs-15 text-black font-600">Save a life and be the reason for a smile!</p>
                                                </div>
                                            </div>
                                            <Link to={{
                                                pathname: "/donor-form",
                                                state: {
                                                    fromPage: 'dashboard'
                                                }
                                            }} className="btn btn-red text-white border-8 d-flex align-items-center fs-12 mt-3 mt-md-0">
                                                <i className="flaticon-heart me-1"></i> Donate Now
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CommonModal modal={modal} toggle={this.toggleModal} content={modalContent} title="Contact" />
            </section>
        );
    }
}

export default Dashboard;
