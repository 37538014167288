import axios from 'axios'
import {
    API_ROOT
} from '../config';


export default function fetch(options) {
    return new Promise((resolve, reject) => {
        let header = {}

        if (options.jsonData) {
            header["Content-Type"] = "application/json"
        }

        axios({
            url: API_ROOT + options.url,
            method: options.method,
            params: options.params,
            headers: header,
            data: options.body,
            withCredentials: true
        }).then((response) => {
            resolve(response.data)
        }).catch((e) => {
            reject(e.response.data)
        })
    })
}