import React, { Component } from "react";
import { Alert, FormGroup } from "reactstrap";
import { updateProfile } from "../../../api/user";
import Coordinates from '../../commonComponents/Coordinates'
import CommonAlert from '../../commonComponents/CommonAlert'
import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from "../../../config";

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage("en");

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            full_name: "",
            location_lat: 0.0,
            location_long: 0.0,
            location: "",
            donor: false,
            blood_group: "",
            alertType: "",
            alertMessage: "",
            alertShow: '',
            coordinates: '',
            coordinatesModal: false
        };
    }

    onInputChange = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    changeLocation = (location) => {
        this.setState({
            location: location
        })

        Geocode.fromAddress(location).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({
                    location_lat: lat,
                    location_lng: lng,
                    coordinates: lat.toString() + "," + lng.toString()
                });
            },
            (error) => {
                console.error(error);
            }
        );
    };

    changeCoordinates = (latitude, longitude) => {
        this.setState({
            location_lat: latitude,
            location_long: longitude,
            coordinates: latitude.toString() + "," + longitude.toString()
        }, () => {
            Geocode.fromLatLng(latitude, longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    this.setState({
                        location: address
                    })
                },
                (error) => {
                    console.error(error);
                }
            );
        });
    }

    toggleCoordinatesModal = () => {
        this.setState(prevState => ({
            coordinatesModal: !prevState.coordinatesModal
        }))
    }

    dismissAlert = () => {
        this.setState({
            alertType: "",
            alertMessage: "",
            alertShow: ''
        })
    }

    submitUpdateProfile = () => {
        const { full_name, location, blood_group, donor, location_lat, location_long } = this.state;
        let valid = true
        let fields = []

        if (!full_name) {
            valid = false
            fields.push('name')
        }
        if (!location) {
            valid = false
            fields.push('location')
        }
        if (!location_lat || !location_long) {
            valid = false
            fields.push('coordinates')
        }

        if (!valid) {
            this.setState({
                alertType: "danger",
                alertMessage: 'Please enter valid ' + fields.join(','),
                alertShow: true,
            }, () => {
                setTimeout(this.dismissAlert, 2000)
            })
            return
        }

        let body = {
            full_name,
            location,
            blood_group_fk: blood_group,
            donor,
            location_lat,
            location_lng: location_long,
        };

        // console.log("body", body);

        updateProfile(body)
            .then((response) => {
                // console.log("response", response);
                if (response.code == 1) {
                    this.props.getUserDetails()
                    if (donor) {
                        // this.props.history.push("/donor-form");
                        this.props.history.push({
                            pathname: '/donor-form',
                            state: {
                                fromPage: 'register'
                            }
                        })
                    } else {
                        this.props.history.push({
                            pathname: "/dashboard",
                            state: {
                                userDetails: response.response,
                            },
                        });
                    }
                }
            })
            .catch((response) => {
                this.setState({
                    alertType: "danger",
                    alertMessage: response.msg,
                });
            });
    };

    render() {
        const { full_name, donor, alertType, alertMessage, alertShow, coordinates, location, coordinatesModal, blood_group } = this.state;

        return (
            <section className="section-content w-100">
                <div className="row h-100 d-md-flex d-block">
                    <div className="col-5 ps-0 d-none d-lg-block">
                        <div className="section-lhs w-100 h-100 d-flex flex-column justify-content-end pb-5">
                            {/* <h1 className="font-lato font-900">Join With Us And
                                            <span className="text-red d-block">Save Life</span>
                                        </h1>
                                        <p className="font-700">Save a life and be the reason for a smile!</p>  */}
                        </div>
                    </div>
                    <div className="col-12 d-lg-none px-0 mt-4">
                        <img src="/media/small-screen-banner.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-7 mt-lg-0 mt-4">
                        <div className="d-flex h-100 align-items-center">
                            <div className="col-xxl-10 ps-lg-4">
                                <div className="section-rhs pe-md-5">
                                    <h2 className="m-0 font-lato font-500 fs-25 mb-2">
                                        Welcome to <strong>Caritas blood bank</strong>
                                    </h2>
                                    {/* <p className="m-0 mt-2 sub-heading">
                                        Your mobile number <strong>{this.props && this.props.location.state && this.props.location.state.mobile_number}</strong> is registered successfully
                                                </p> */}
                                    <CommonAlert color={alertType} show={alertShow} message={alertMessage} />
                                    <form>
                                        <div className="mb-3 mt-3">
                                            <label htmlFor="name" className="form-label">
                                                Full name
                                                        </label>
                                            <input type="name" className="form-control height-45 border-8" id="name" aria-describedby="nameHelp" value={full_name} onChange={(e) => this.onInputChange("full_name", e.target.value)} placeholder="Enter your full name" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputName1" className="form-label">
                                                Blood Group (optional)
                                                        </label>
                                            <div className="row g-0">
                                                <div className="col-sm-6">
                                                    <div className="row g-0">
                                                        {
                                                            this.props && this.props.bloodGroupList && this.props.bloodGroupList.length > 0 &&
                                                            this.props.bloodGroupList.map((group, index) => {
                                                                return (
                                                                    <div className="select-bgroup col-3" key={index}>
                                                                        <input type="radio" name="b-group" onChange={(e) => this.onInputChange("blood_group", group.id)} id={group.blood_group} value={group.id} />
                                                                        <label htmlFor={group.blood_group} className={(blood_group == group.id) ? "me-2 blood-group-checked" : "me-2"}>
                                                                            {group.blood_group}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="location" className="form-label">
                                                Location
                                                        </label>
                                            <div className="d-flex">
                                                <input type="name" className="form-control height-45 border-8 location-field" id="location" value={location} aria-describedby="nameHelp" placeholder="Enter your location" onChange={(e) => this.changeLocation(e.target.value)} />
                                                {
                                                    !coordinates &&
                                                    <button onClick={this.toggleCoordinatesModal} type="button" className="btn btn-location d-flex align-items-center justify-content-center">
                                                        <i className="flaticon-place"></i>
                                                    </button>
                                                }
                                                {
                                                    coordinates &&
                                                    <button onClick={this.toggleCoordinatesModal} type="button" className="btn btn-location d-flex align-items-center justify-content-center">
                                                        Edit
                                                                        </button>
                                                }
                                            </div>
                                        </div>
                                        {/* <div className="mb-3">
                                            <label htmlFor="coordinates" className="form-label">
                                                Coordinates
                                                                </label>
                                            <div className="d-flex">
                                                <input type="text" className="form-control height-45 border-8 location-field" value={coordinates} id="location" aria-describedby="nameHelp" placeholder="Enter your coordinates" />
                                                {
                                                    !coordinates &&
                                                    <button onClick={this.toggleCoordinatesModal} type="button" className="btn btn-location d-flex align-items-center justify-content-center">
                                                        <i className="flaticon-place"></i>
                                                    </button>
                                                }
                                                {
                                                    coordinates &&
                                                    <button onClick={this.toggleCoordinatesModal} type="button" className="btn btn-location d-flex align-items-center justify-content-center">
                                                        Edit
                                                                        </button>
                                                }
                                            </div>
                                        </div> */}
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputName1" className="form-label">
                                                Select option
                                                        </label>
                                            <div className="row g-2">
                                                <div className="d-flex select-drs col-sm-6">
                                                    <input type="radio" name="userType" id="donor" onChange={(e) => this.onInputChange("donor", true)} />
                                                    <label htmlFor="donor" className="me-2 px-4 py-3">
                                                        <i className="flaticon-blood-donation-3"></i>
                                                        <span className="ms-2">
                                                            <h3 className="m-0">Donate Blood</h3>
                                                            <p className="m-0">Save a life and be the reason for smile</p>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="d-flex select-drs col-sm-6">
                                                    <input type="radio" name="userType" id="requester" onChange={(e) => this.onInputChange("donor", false)} />
                                                    <label htmlFor="requester" className="px-4 py-3">
                                                        <i className="flaticon-blood-bag"></i>
                                                        <span className="ms-2">
                                                            <h3 className="m-0">Request Blood</h3>
                                                            <p className="m-0">You can request a matching blood group</p>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <button type="button" onClick={this.submitUpdateProfile} className="btn btn-blue text-white height-45 border-8 px-5 w-50">
                                                Submit
                                                        </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Coordinates modal={coordinatesModal} toggle={this.toggleCoordinatesModal} location={location} changeCoordinates={this.changeCoordinates} />

            </section>
        );
    }
}

export default Register;
