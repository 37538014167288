import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CommonAlert from "../../../commonComponents/CommonAlert";

class Reject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rejectReason: "",
            alertType: "",
            alertMessage: "",
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.modal != prevProps.modal) {
            this.setState({
                rejectReason: "",
                alertType: "",
                alertMessage: "",
            });
        }
    };

    onInputChange = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    dismissAlert = () => {
        this.setState({
            alertType: "",
            alertMessage: "",
        });
    };

    submitRejectReq = () => {
        const { rejectReason } = this.state;

        if (!rejectReason) {
            this.setState(
                {
                    alertType: "danger",
                    alertMessage: "Please select any of the following reasons",
                },
                () => {
                    setTimeout(this.dismissAlert, 2000);
                }
            );
            return;
        }

        this.props.submitRejectReq(rejectReason);
    };

    render() {
        const { alertType, alertMessage } = this.state;
        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Cancel Request</ModalHeader>
                <ModalBody>
                    <CommonAlert color={alertType} message={alertMessage} show={alertMessage ? true : false} />
                    <div className="mb-3 mt-3">
                        <label htmlFor="exampleInputName1" className="form-label">
                            Please select a reason and submit
                        </label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={() => this.onInputChange("rejectReason", "Non-communicable diseases")} />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Non-communicable diseases
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={() => this.onInputChange("rejectReason", "Medical and surgical interventions")} />
                            <label class="form-check-label" for="flexRadioDefault2">
                                Medical and surgical interventions
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onChange={() => this.onInputChange("rejectReason", "Donated blood recently")} />
                            <label class="form-check-label" for="flexRadioDefault3">
                                Donated blood recently
                            </label>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-blue text-white  height-45 border-8 px-md-5 px-4 border-0 btn btn-secondary" onClick={this.submitRejectReq}>
                        Confirm
                    </Button>
                    <Button className="btn btn-gray text-black  height-45 border-8 px-md-5 px-4 border-0 me-3 btn btn-secondary" onClick={this.props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default Reject;


