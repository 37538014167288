import React, { Component, Fragment } from "react";
import EditUser from "./Edit";
import { userLogout } from "../../../api/user";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            showDropdown: false,
            editProfileModal: false
        }
    }

    componentDidMount = () => {
        const loggedIn = localStorage.getItem("isLoggedIn");
        this.setState({
            loggedIn: loggedIn ? true : false
        })
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            showDropdown: !prevState.showDropdown
        }))
    }

    toggleEditProfileModal = () => {
        this.setState(prevState => ({
            editProfileModal: !prevState.editProfileModal
        }))
    }

    logout = () => {
        userLogout().then((response) => {
            if (response) {
                localStorage.removeItem("isLoggedIn")
                localStorage.removeItem("notificationOpen")
                this.props.history.push("/login");
            }
        })
    }

    render() {

        const { loggedIn, showDropdown, editProfileModal } = this.state

        return (
            <header className="header pt-3 w-100">
                <div className="row">
                    <div className="col ps-xxl-5">
                        <img src="/media/caritas-bb-logo.png" className="img-fluid bb-logo" alt="" />
                    </div>
                    <div className="col col-sm-8 ps-xxl-5">
                        <div className="d-flex align-items-center justify-content-end h-100 pe-xxl-4">
                            <a href="#" className="m-0 me-3 text-black text-normal text-decoration-none h-links">
                                <span className="d-md-block d-none">Email: mail@caritashospital.org</span>
                                <i className="flaticon-email d-md-none text-white"></i>
                            </a>
                            <a href="#" className="btn btn-red text-white border-10 h-links">
                                <span className="d-md-block d-none">0481-2790025</span>
                                <i className="flaticon-call-center d-md-none"></i>
                            </a>
                            {
                                loggedIn &&
                                <div className="dropdown">
                                    <a onClick={this.toggleDropdown} className="btn btn-blue text-white border-10 h-links ms-2" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="flaticon-user"></i>
                                    </a>
                                    <ul className={showDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton1">
                                        {
                                            !window.location.href.includes('register') &&
                                            <li><button onClick={this.toggleEditProfileModal} className="dropdown-item">Edit Profile</button></li>
                                        }
                                        <li><button onClick={this.logout} className="dropdown-item">Logout</button></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <EditUser modal={editProfileModal} toggle={this.toggleEditProfileModal} details={this.props && this.props.userDetails} bloodGroupList={this.props && this.props.bloodGroupList} getUserDetails={this.props.getUserDetails} toggleDropdown={this.toggleDropdown} />
            </header>
        );
    }
}

export default Header;
