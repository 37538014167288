import React, { Component } from "react";
import { FormGroup, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../commonComponents/Header";
import Footer from "../../commonComponents/Footer";
import { requestBlood, getMatchingDonorCount } from "../../../api/notification";
import Coordinates from "../../commonComponents/Coordinates";
import MatchingDonorCount from "./MatchingDonorCount";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from "../../../config";
import moment from 'moment'

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage("en");

class BloodRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blood_group: "",
            reqDate: "",
            reqTime: "",
            location: "",
            location_lat: "",
            location_lng: "",
            blood_element: "",
            alertType: "",
            alertMessage: "",
            coordinatesModal: false,
            coordinates: "",
            matchingDonorCount: -1,
            matchingDonorCountModal: false,
            bloodRequestId: -1,
        };
    }

    onInputChange = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    changeLocation = (location) => {
        this.setState({
            location: location
        })

        Geocode.fromAddress(location).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({
                    location_lat: lat,
                    location_lng: lng,
                    coordinates: lat.toString() + "," + lng.toString()
                });
            },
            (error) => {
                console.error(error);
            }
        );
    };

    changeCoordinates = (latitude, longitude) => {
        this.setState({
            location_lat: latitude,
            location_lng: longitude,
            coordinates: latitude.toString() + "," + longitude.toString(),
        }, () => {
            Geocode.fromLatLng(latitude, longitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    this.setState({
                        location: address
                    })
                },
                (error) => {
                    console.error(error);
                }
            );
        });
    };

    redirectToDashboard = () => {
        this.setState(
            {
                alertMessage: "",
                alertType: "",
            },
            () => {
                this.props.history.push("/dashboard");
            }
        );
    };

    dismissAlert = () => {
        this.setState({
            alertType: "",
            alertMessage: "",
        });
    };

    toggleCoordinatesModal = () => {
        this.setState((prevState) => ({
            coordinatesModal: !prevState.coordinatesModal,
        }));
    };

    toggleMatchingDonorCountModal = () => {
        this.setState((prevState) => ({
            matchingDonorCountModal: !prevState.matchingDonorCountModal,
        }));
    };

    submitBloodRequest = () => {
        const { blood_group, reqDate, reqTime, location_lat, location_lng, blood_element, location } = this.state;
        let valid = true;
        let fields = []

        if (!blood_group) {
            valid = false;
            fields.push('blood group')
        }
        if (!reqDate) {
            valid = false;
            fields.push('required date')
        }
        if (!reqTime) {
            valid = false;
            fields.push('required time')
        }
        if (!location) {
            valid = false;
            fields.push('location')
        }
        if (!location_lat || !location_lng) {
            valid = false
            fields.push('coordinates')
        }

        if (!valid) {
            this.setState(
                {
                    alertType: "danger",
                    alertMessage: 'Please enter valid ' + fields.join(','),
                },
                () => {
                    setTimeout(this.dismissAlert, 2000);
                }
            );

            return;
        }

        let body = {
            blood_group_fk: parseInt(blood_group, 10),
            date: reqDate,
            time: reqTime,
            location_lat,
            location_lng,
            blood_element,
            location,
            blood_element,
        };

        // console.log("body", body);

        getMatchingDonorCount(body).then((data) => {
            console.log("natching pair resp", data);
            if (data) {
                this.setState(
                    {
                        matchingDonorCount: data.response.count,
                        bloodRequestId: data.response.request_id,
                    },
                    () => {
                        this.toggleMatchingDonorCountModal();
                    }
                );
            }
        });
    };

    placeBloodRequest = () => {
        this.toggleMatchingDonorCountModal();

        let body = {
            request_id: this.state.bloodRequestId,
        };
        requestBlood(body).then((data) => {
            // console.log("dataa", data);
            if (data.code == 1) {
                this.setState(
                    {
                        alertMessage: "Blood requested successfully",
                        alertType: "success",
                    },
                    () => {
                        setTimeout(this.redirectToDashboard, 2000);
                    }
                );
            }
        }).catch((data) => {
            if (data) {
                this.setState({
                    alertMessage: data.msg,
                    alertType: 'danger',
                }, () => {
                    setTimeout(this.dismissAlert, 2000)
                })
            }
        })
    };

    render() {
        const { blood_group, reqDate, reqTime, location, coordinates, blood_element, alertType, alertMessage, coordinatesModal, matchingDonorCountModal, matchingDonorCount } = this.state;
        const today = moment().format('YYYY-MM-DD')

        return (
            // <div className="wrapper d-flex align-items-center justify-content-center flex-column">
            //     <div className="main-container container">
            //         <div className="d-flex flex-column h-100 justify-content-between">
            //             <Header />

            <section className="section-content w-100">
                <div className="d-xl-flex h-100 d-block px-xxl-4 px-1">
                    <div className="dashboard-lhs bg-offwhite border-10 me-xl-3 p-xl-4 p-2 d-flex flex-column justify-content-between mb-3 mb-xl-0">
                        <div className="topnav d-md-flex flex-xl-column justify-content-md-around justify-content-between position-relative" id="myTopnav">
                            <Link to="/dashboard">
                                <i className="flaticon-home"></i>Dashboard
                                        </Link>
                            <Link to="/my-requests">
                                <i className="flaticon-blood-bag"></i>My Requests
                                        </Link>
                            <Link to="/donate-list">
                                <i className="flaticon-blood-donation-4"></i>My Donations
                                        </Link>
                            <Link to="/blood-request-list">
                                <i className="flaticon-user"></i>Blood donation requests
                                        </Link>
                        </div>
                    </div>
                    <div className="dashboard-rhs bg-offwhite border-10 p-sm-3 p-2">
                        <div className="dashboard-rhs-body mt-0 h-100">
                            <div className="row h-100">
                                <div className="col-12 d-flex flex-column h-100 mt-md-0 mb-3 h-100">
                                    <div className="bg-white border-10 p-3 pb-0 h-100">
                                        {alertMessage && <Alert color={alertType}>{alertMessage}</Alert>}
                                        <h4 className="h5 font-600 mb-3">New Blood Request</h4>
                                        <form className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputName1" className="form-label">
                                                        Blood Group
                                                                </label>
                                                    <div className="row g-0">
                                                        <div className="col-sm-12">
                                                            <div className="row g-0">
                                                                {this.props &&
                                                                    this.props.bloodGroupList &&
                                                                    this.props.bloodGroupList.length > 0 &&
                                                                    this.props.bloodGroupList.map((group, index) => {
                                                                        return (
                                                                            <div className="select-bgroup col-1 mb-2" key={index}>
                                                                                <input type="radio" name="b-group" onChange={(e) => this.onInputChange("blood_group", e.target.value)} id={group.blood_group} value={group.id} />
                                                                                <label htmlFor={group.blood_group} className={(blood_group == group.id) ? "me-2 blood-group-checked" : "me-2"} >
                                                                                    {group.blood_group}
                                                                                </label>
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="date" className="form-label">
                                                        Date
                                                                    </label>
                                                    <input type="date" className="form-control text-lowercase height-45 border-8" onChange={(e) => this.onInputChange("reqDate", e.target.value)} value={reqDate} min={today} id="date" aria-describedby="nameHelp" placeholder="DD-MM-YYYY" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="time" className="form-label">
                                                        Time (24hr)
                                                                    </label>
                                                    <input type="time" id="time" className="form-control height-45 border-8" onChange={(e) => this.onInputChange("reqTime", e.target.value)} value={reqTime} id="time" aria-describedby="nameHelp" placeholder="00:00" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mt-4 mt-lg-0">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputName1" className="form-label">
                                                        Type of blood/elements
                                                                </label>
                                                    <select className="form-select height-45 border-8" aria-label="Default select example" onChange={(e) => this.onInputChange("blood_element", e.target.value)} value={blood_element}>
                                                        <option value="">Select From The List</option>
                                                        <option value="Packed RBC">Packed RBC</option>
                                                        <option value="SDP Single Donor Platelets">SDP Single Donor Platelets</option>
                                                        <option value="RDP Random Donor Platelets">RDP Random Donor Platelets</option>
                                                        <option value="Others">Others</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="location" className="form-label">
                                                        Location
                                                                </label>
                                                    <div className="d-flex">
                                                        <input type="name" className="form-control height-45 border-8 location-field" onChange={(e) => this.changeLocation(e.target.value)} value={location} id="location" aria-describedby="nameHelp" placeholder="Enter your location" />
                                                        {!coordinates && (
                                                            <button onClick={this.toggleCoordinatesModal} type="button" className="btn btn-location d-flex align-items-center justify-content-center">
                                                                <i className="flaticon-place"></i>
                                                            </button>
                                                        )}
                                                        {coordinates && (
                                                            <button onClick={this.toggleCoordinatesModal} type="button" className="btn btn-location d-flex align-items-center justify-content-center">
                                                                Edit
                                                                        </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-lg-0 mt-3">
                                                <div className="border-top py-3 d-flex justify-content-end">
                                                    <Link to="/dashboard" className="btn btn-gray text-black  height-45 border-8 px-md-5 px-4 me-3">
                                                        Cancel
                                                                </Link>
                                                    <button type="button" onClick={this.submitBloodRequest} className="btn btn-blue text-white  height-45 border-8 px-md-5 px-4">
                                                        Submit
                                                                </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Coordinates modal={coordinatesModal} toggle={this.toggleCoordinatesModal} location={location} changeCoordinates={this.changeCoordinates} />
                <MatchingDonorCount toggle={this.toggleMatchingDonorCountModal} modal={matchingDonorCountModal} count={matchingDonorCount} placeBloodRequest={this.placeBloodRequest} />

            </section >

        );
    }
}

export default BloodRequest;
